img {
  border-radius: 5%;
  border-width: 2px;
  border-color: black;
}

[class^="awsui_logo_"] {
  border-radius: 0px !important;
  border-width: 0px !important;
  border-color: unset !important;
}

body {
  background: #101c2c;
  overflow-x: hidden;
}

.top-navigation {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.chat-image {
  padding-top: 5px;
  padding-bottom: 5px;
}

/* @media screen and (min-width: 1006px) {
  .chat-feedback-s {
    position: absolute;
    bottom: 0;
  }

  .chat-feedback-xs {
    display: none;
  }
} */


/* @media screen and (max-width: 1005px) {
  .chat-feedback-s {
    display: none;
  }
} */


.turn-container {
  min-height: 415px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.turn-exit {
  opacity: 0;
  transition: opacity 1s;
}

.turn-exit-active {
  opacity: 0;
}

.turn-enter {
  opacity: 0;
}

.turn-enter-overlay {
  position: absolute;
  padding-right: 20px;
}

.turn-enter-active {
  opacity: 1;
  transition: opacity 1s;
  transition-delay: 1s;
}

.turn-cancel {
  display: none;
}

.room-item-first {
  padding: 0 10px;
  margin: 8px 0;
  border-left-width: 0;
  padding-left: 0;
}

.room-item-info {
  padding: 0 10px;
  margin: 8px 0;
  border-left: 2px solid var(--color-border-divider-default-7s2wjw, #e9ebed);
}

.tts-progress {
  margin-top: 3px;
}

.tts-progress-range {
  --range-progress: 0;
  -webkit-appearance: none;
  position: relative;
  background: #ccc;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  cursor: pointer;
}

/* Input range - firefox */
.tts-progress-range::-moz-range-track {
  position: relative;
  background: #ccc;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  cursor: pointer;
}

/* played progress length - Chrome & safari*/
.tts-progress-range::before {
  content: '';
  height: 4px;
  background: #539fd9;
  width: var(--range-progress);
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
}

/* played progress length - firefox */
.tts-progress-range::-moz-range-progress {
  background: #539fd9;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  height: 4px;
}

/* slider thumb - chrome and safari */
.tts-progress-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 4px;
  border-radius: 20%;
  border: none;
  background-color: #539fd9;
  cursor: pointer;
  position: relative;
}

/* dragging thumb - chrome and safari */
.tts-progress-range:active::-webkit-slider-thumb {
  transform: scale(1.3);
}

/* slider thumb - firefox */
.tts-progress-range::-moz-range-thumb {
  height: 16px;
  width: 4px;
  border-radius: 20%;
  background: #539fd9;
  cursor: pointer;
  border: transparent;
  position: relative;
}

/* dragging thumb - firefox */
.tts-progress-range:active::-moz-range-thumb {
  transform: scale(1.3);
}

.audio-status {
  display: none;
}

.audio-container {
  max-width: 110px;
}

div[class^="audio-recording"]>button {
  color: crimson !important;
  border-color: crimson !important;
}

@media screen and (min-width: 688px) {
  .escape-chat-box {
    position: fixed;
    bottom: 20px;
    left: 50%;
    /* left: 20px; */
    right: 20px;
  }
}

@media screen and (max-width: 687px) {
  .escape-chat-box {
    position: fixed;
    bottom: 20px;
    /* left: 50%; */
    left: 20px;
    right: 20px;
  }
}